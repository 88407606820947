// DiningOut.jsx
import React, { useState, useEffect, useRef} from "react";
import {
    Button,
} from "@mui/material";
import {printOrder , getIncomeAPI} from "../../../utils/ordersControlar.js";

function ProductIncome() {

    const [income , setIncome] = useState(null) ;

    const handleIncomedata = async () => {
        try {
            const income = await getIncomeAPI();
            setIncome(income);
        } catch (error) {
            console.error("Failed to change order status:", error);
        }
    };

    useEffect(()=>{
        if(!income){
            handleIncomedata() ;
        }
    },[])

    return (
        income ? (
            <>
                <font size={12}>
                    商品銷售
                </font>

            </>
        ) : "載入中..."
    ) ;
}

export default ProductIncome;
