// OrderHistory.jsx
import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Button,
  ButtonGroup,
  TextField,
  Typography,
} from "@mui/material";
import { cx } from "../../utils/cx.js";
import { statusTable, payment, orderTypes } from "../../utils/status_table";
import { useLocation, useNavigate } from "react-router-dom";
import { getOrders, removeOrder } from "../../utils/ordersControlar.js";
import { useGlobalOrder } from "../../utils/GlobalStateContext.jsx";
import Option from "../Dialogs/Option.tsx";
import Delete from "../Dialogs/Delete.tsx";
import OrderRow from "./OrderRow";
function OrderHistory() {
  const debounceTimeout = useRef(null);
  const { systemData, setGlobalOrder } = useGlobalOrder();
  const [filter, setFilter] = useState({
    start_time: "",
    end_time: "",
    method: "",
    search: "",
  });
  const [ordersData, setOrdersData] = useState(null);
  const [inputValue, setInputValue] = useState(""); // 用來暫存用戶輸入的值
  const [orderDetail, setOrderDetail] = useState(null);
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null); // 使用 useRef 獲取滾動容器的參考
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const buycart = orderDetail;
  const [dialogOpen, setDialogOpen] = useState({
    option: false,
    delete: false,
  });
  // 打開彈窗
  const handleOpenDialog = (dialogType) => {
    console.log(dialogType);
    setDialogOpen((prev) => ({ ...prev, [dialogType]: true }));
  };

  // 關閉彈窗
  const handleCloseDialog = (dialogType) => {
    setDialogOpen((prev) => ({ ...prev, [dialogType]: false }));
  };

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight; // 滾動到底部
    }
  }, [buycart]);

  // 計算總金額
  const totalAmount = orderDetail?.buycart?.reduce((acc, item) => {
    const mealData = systemData.meal.find((data) => data.id === item.meal_id);
    const mainItemPrice = mealData ? mealData.price * item.qty : 0;
    const detailPrice = item.detail
      ? item.detail.reduce((tagAcc, tag) => {
          const foodItem = systemData.food.find(
            (food) => food.pd_id === tag.food_id
          );
          const tagPrice = foodItem ? foodItem.price * tag.qty : 0;
          return tagAcc + tagPrice;
        }, 0)
      : 0;
    return acc + mainItemPrice + detailPrice * item.qty;
  }, 0);

  const fetchOrders = async () => {
    const order = await getOrders({
      start_time: filter.start_time.replace(/-/g, "/"),
      end_time: filter.end_time.replace(/-/g, "/"),
      method: filter.method,
      search: filter.search,
    });
    setOrdersData(order);
  };
  const handleStartDateChange = (e) => {
    setFilter({ ...filter, start_time: e.target.value });
  };

  const handleEndDateChange = (e) => {
    setFilter({ ...filter, end_time: e.target.value });
  };

  const handleMethodChange = (value) => {
    if (filter.method === value) {
      setFilter({ ...filter, method: "" });
    } else {
      setFilter({ ...filter, method: value });
    }
  };

  const handleSearchChange = (e) => {
    const newValue = e.target.value; // 更新輸入框的值
    setInputValue(newValue); // 更新本地狀態

    // 當輸入的值為空時清空 filter.search
    if (newValue.length === 0) {
      setFilter((prev) => ({ ...prev, search: "" }));
    }
  };

  const handleSearchSubmit = () => {
    setFilter((prev) => ({ ...prev, search: inputValue })); // 按下按鈕時更新 filter.search
  };

  useEffect(() => {
    // 清除上一個 timeout，並重新設置新的 timeout
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // 設置一個新的 timeout 來執行 fetchOrders
    debounceTimeout.current = setTimeout(() => {
      fetchOrders();
    }, 500); // 延遲 1 秒

    // 清理 function，當下一次 effect 觸發時會清除這次的 timeout
    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, [filter]);

    // 計算總金額

  const history_type = [
    { title: "單號", xs: 2 },
    { title: "狀態", xs: 2 },
    { title: "訂購人", xs: 2 },
    { title: "付款", xs: 2 },
    { title: "金額", xs: 1 },
    { title: "印單於", xs: 2 },
  ];
  return (
    <Grid
      container
      direction="column"
      sx={{ backgroundColor: "#f0f0f0", height: "100vh" }}
    >
      {/* 上方ButtonGroup区域，用于选择订单类型 */}
      <Grid
        container
        spacing={2}
        sx={{
          marginX: "0px !important",
          height: 92,
          alignItems: "center",
          width: "100%",
          padding: "0px 16px",
          gap: "16px",
          flexWrap: "nowrap",
          overflow: "auto",
        }}
        className="hide-scrollbar"
      >
        {/* <Grid item>
          <p className="min-w-[44px] sidebar_btn_text_b text-sold_out_gray text-left">
            篩選
          </p>
        </Grid> */}
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          {/* 开始日期选择 */}
          <TextField
            type="date"
            value={filter.start_time}
            onChange={handleStartDateChange}
            InputProps={{
              sx: {
                minWidth: "180px",
                fontSize: "16px",
                color: "#797777",
                backgroundColor: "#FFF",
                height: "48px",
                borderRadius: "9999px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                "&::placeholder": {
                  color: "#C0C0C0", // 自定义占位符颜色
                },
                "&::-webkit-calendar-picker-indicator": {
                  display: "none", // 隐藏日历图标
                },
              },
            }}
          />
          <p className="sidebar_btn_text_b text-sold_out_gray text-left">~</p>
          {/* 结束日期选择 */}
          <TextField
            type="date"
            value={filter.end_time}
            onChange={handleEndDateChange}
            InputProps={{
              sx: {
                minWidth: "180px",
                fontSize: "16px",
                color: "#797777",
                backgroundColor: "#FFF",
                height: "48px",
                borderRadius: "9999px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                "&::placeholder": {
                  color: "#C0C0C0", // 自定义占位符颜色
                },
                "&::-webkit-calendar-picker-indicator": {
                  display: "none", // 隐藏日历图标
                },
              },
            }}
          />
        </Grid>
        {/* 訂單類型選擇 */}
        <Grid item>
          <ButtonGroup
            sx={{
              overflow: "hidden",
              height: "48px",
            }}
            aria-label="Order type selection"
          >
            {orderTypes.map((type) => (
              <Button
                key={type.value}
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  px: 3,
                  border: "1px solid #8E8E8E",
                  borderRadius: 4,
                  borderColor:
                    filter.method === type.value ? "#FFF" : "#8E8E8E",
                  backgroundColor:
                    filter.method === type.value ? "#FFF" : "#8E8E8E",
                  color: filter.method === type.value ? "#A43B3B" : "#FFF",
                }}
                onClick={() => handleMethodChange(type.value)}
              >
                <p className="flex min-w-[36px]">{type.label}</p>
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
        {/* 搜索框 */}
        <Grid
          item
          sx={{
            width: "fit-content",
            display: "flex",
            gap: "8px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            label="搜尋名稱/電話"
            variant="outlined"
            value={inputValue} // 使用 inputValue 來顯示輸入框的值
            onChange={handleSearchChange} // 更新輸入框的值
            InputProps={{
              sx: {
                minWidth: "180px",
                fontSize: "16px",
                color: "#797777",
                backgroundColor: "#FFF",
                height: "48px",
                borderRadius: "9999px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                "&::placeholder": {
                  color: "#C0C0C0", // 自定义占位符颜色
                },
                "&::-webkit-calendar-picker-indicator": {
                  display: "none", // 隐藏日历图标
                },
              },
            }}
            InputLabelProps={{
              sx: {
                minWidth: "180px",
                fontSize: "16px",
                color: "#797777",
                display: "flex",
                alignItems: "center",
                height: "34%",
              },
            }}
          />
          <Button
            variant="contained"
            onClick={handleSearchSubmit}
            fullWidth
            sx={{
              height: "48px",
              width: "84px",
              display: "flex",
              backgroundColor: "#FFF",
              borderRadius: 7,
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <p className="sidebar_btn_text_b text-dark_red flex">送出</p>
          </Button>
        </Grid>
      </Grid>

      {/* 下方区域 */}
      <Grid
        item
        container
        direction="row"
        sx={{
          height: "89%",
          flexGrow: 1,
          display: "flex",
          flexWrap: "nowrap",
          border: "none",
          borderTop: "1px solid #797777",
        }}
      >
        <Grid
          item
          container
          direction="column"
          sx={{
            paddingY: 2,
            paddingX: 2,
            border: "none",
            borderRight: "1px solid #797777",
            height: "100%", // 主容器的高度
            overflow: "hidden", // 隱藏主容器滾動條
          }}
          className="hide-scrollbar"
          xs={9}
        >
          {/* 標題 */}
          <Grid
            container
            direction="row"
            sx={{
              marginBottom: 1,
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              border: "none",
              borderBottom: "1px solid #797777",
            }}
          >
            {history_type.map((type) => (
              <Grid key={type.title} item xs={type.xs}>
                <p className="sidebar_btn_text_b text-sold_out_gray text-left text-center justify-center items-center flex mb-[16px]">
                  {type.title}
                </p>
              </Grid>
            ))}
          </Grid>

          {/* 訂單 */}
          <Grid
            container
            direction="column"
            sx={{
              flex: 1, // 填滿剩餘空間
              overflowY: "auto", // 允許垂直滾動
              flexWrap: "nowrap",
              gap: "16px",
            }}
            className="hide-scrollbar"
          >
            {ordersData ? (
              ordersData?.map((order) => (
                <Grid
                  key={order.order_id}
                  container
                  direction="row"
                  sx={{
                    background:
                      selectedOrderId === order.order_id
                        ? "rgb(67,67,67)" // 點擊後背景顏色
                        : [0, 1, 2].includes(order.status)
                        ? "#797777"
                        : "#FFF",
                    borderRadius: 4,
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    border: "none",
                    cursor: "pointer", // 增加指針效果
                  }}
                  onClick={() => {
                    setOrderDetail(order);
                    setSelectedOrderId(order.order_id); // 設置選中的訂單
                  }}
                >
                    {<OrderRow order={order} key={order} />}
                </Grid>
              ))
            ) : (
              <h1 className="system_login_title text-dark_red flex items-center justify-center h-full w-full">
                請選擇搜尋區間
              </h1>
            )}
          </Grid>
        </Grid>

        {/* 右侧订单详情区域 */}
        <Grid
          item
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
          className="hide-scrollbar"
          xs={3}
        >
            {ordersData?.note1 ? (
                <Typography
                    variant="h6"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        bgcolor: "#053d4e",
                        height: "30px",
                        marginX: "-16px",
                        marginTop: "-16px",
                        paddingX: "30px",
                    }}
                >
                    <p className="text_default_style text-[#FFF]">{ordersData?.note1}</p>
                </Typography>
            ) : ''}

            {ordersData?.note2 ? (
                <Typography
                    variant="h6"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        bgcolor: "#4c4c4c",
                        height: "30px",
                        marginX: "-16px",
                        marginTop: "-16px",
                        paddingX: "30px",
                    }}
                >
                    <p className="text_default_style text-[#FFF]">{ordersData?.note2}</p>
                </Typography>
            ) : ''}

          <Grid
            container
            sx={{
              marginBottom: 2,
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              paddingX: "8px",
            }}
          >
            <Grid item xs={6} sx={{ paddingLeft: "8px" }}>
              <p className="sidebar_btn_text_b text-sold_out_gray text-left">
                品名
              </p>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p className="sidebar_btn_text_b text-sold_out_gray">數量</p>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p className="sidebar_btn_text_b text-sold_out_gray">單價</p>
            </Grid>
          </Grid>
          {orderDetail?.buycart && (
            <div
              ref={scrollContainerRef}
              style={{
                height: "calc(100% - 295px)",
                overflow: "auto",
                scrollBehavior: "smooth",
              }}
              className="hide-scrollbar"
            >
              {orderDetail?.buycart?.length > 0 &&
                orderDetail?.buycart?.map((item, index) => {
                  const mealData = systemData.meal.find(
                    (data) => data.id === item.meal_id
                  );
                  return (
                    <Grid
                      container
                      key={index}
                      sx={{
                        marginBottom: 1,
                        backgroundColor: "#FFF",
                        borderRadius: "8px",
                        padding: "8px",
                      }}
                    >
                      <>
                        <Grid item xs={1}>
                          <p className="sidebar_btn_text_b  text-left">
                            {`${index + 1}.`}
                          </p>
                        </Grid>
                        <Grid item xs={5}>
                          <p className="sidebar_btn_text_b  text-left">
                            {mealData?.name || "已下架商品"}
                          </p>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <p className="sidebar_btn_text_b">{item?.qty}</p>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <p className="sidebar_btn_text_b">
                            ${item?.money}
                          </p>
                        </Grid>
                      </>
                      {item.detail?.length > 0 && (
                        <Grid
                          sx={{
                            marginY: "4px",
                            gap: "4px",
                          }}
                          display={"flex"}
                          flexWrap={"wrap"}
                        >
                          {item.detail.map((tag) => {
                            // Find the corresponding food item from systemData.food based on categoryId
                            const foodData = systemData.food.find(
                              (food) => food.pd_id === tag.food_id
                            );
                            return foodData ? (
                              <span
                                key={tag.oi_id}
                                className="sidebar_btn_text_b text-[16px] w-fit flex text-nowrap bg-card_bg_gray rounded-full p-[4px_8px] text-[#FFF]"
                              >
                                {foodData?.price > 0
                                  ? `+ ${foodData.name} $ ${foodData.price}`
                                  : `${foodData.name}`}
                              </span>
                            ) : (
                              ""
                            );
                          })}
                        </Grid>
                      )}

                      {item.note && (
                        <Grid item xs={12}>
                          <p className="sidebar_btn_text_b text-ligth_red text-left">{`備註:${item.note}`}</p>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
            </div>
          )}

          <Grid sx={{ marginTop: "auto" }}>
            <Grid
              direction="row"
              container
              sx={{ marginBottom: 2, fontWeight: "bold" }}
              spacing={2}
            >
              <Grid item xs={6} sx={{ marginLeft: "auto" }}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  sx={{
                    backgroundColor: "#FFF",
                    height: "52px",
                    borderRadius: 7,
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    padding: "8px 16px",
                  }}
                  disabled={!orderDetail}
                  onClick={() => handleOpenDialog('option')}
                >
                  <p className="text_default_style text-[20px] text-sold_out_gray font-[700]">
                    操作
                  </p>
                </Button>
              </Grid>
            </Grid>
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                bgcolor: "#838383",
                height: "64px",
                marginX: "-16px",
                alignItems: "center",
                paddingX: "16px",
              }}
            >
              <p className="text_default_style text-[#FFF]">{`消費金額:`}</p>
              <p className="text_default_style text-[#FFF]">${totalAmount}</p>
            </Typography>
            <Grid
              direction="row"
              container
              sx={{ marginTop: 2, fontWeight: "bold" }}
              spacing={2}
            >
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  sx={{
                    backgroundColor: "#FFF",
                    height: "80px",
                    borderRadius: 7,
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    padding: "8px 16px",
                  }}
                  disabled={!orderDetail || [7, 8].includes(orderDetail?.status)}
                  onClick={() => handleOpenDialog('delete')}
                >
                  <p className="text_default_style text-[#000] font-[700]">
                    刪單
                  </p>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  disabled={!orderDetail}
                  sx={{
                    backgroundColor: "#FFF",
                    height: "80px",
                    borderRadius: 7,
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    padding: "8px 16px",
                  }}
                  onClick={() => {
                      setGlobalOrder({
                          ...orderDetail, // 展開 orderDetail 物件
                          mode: (([0, 1, 2].includes(orderDetail?.status)
                              ? 1
                              : [7, 8].includes(orderDetail?.status)
                                  ? 3
                                  : 2))
                      });
                      navigate("/order");
                  }}
                >
                  <p className="text_default_style text-dark_red font-[700]">
                    {[0, 1, 2].includes(orderDetail?.status)
                      ? "提單"
                      : [7, 8].includes(orderDetail?.status)
                      ? "重訂"
                      : "改單"}
                  </p>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {orderDetail && (
        <>
          <Option
            open={dialogOpen.option}
            handleClose={() => handleCloseDialog('option')}
            orderId={orderDetail.order_id}
          />
          <Delete 
            setOrdersData={setOrdersData} 
            open={dialogOpen.delete}
            handleClose={() => handleCloseDialog('delete')}
            orderId={orderDetail.order_id}
          />
        </>
      )}
    </Grid>
  );
}

export default OrderHistory;
