import React, { createContext, useState, useEffect, useContext, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {getOrders, getOrderStatusAPI} from "./ordersControlar";

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [systemData, setSystemData] = useState(null);
    const [globalOrder, setGlobalOrder] = useState(null);
    const [todayOrders, setTodayOrders] = useState(null);
    const [foodData, setFoodData] = useState(null);
    const [ordersStatusList, setOrderStatusList] = useState(null);
    const [ws, setWs] = useState(null);

    const audioRef = useRef(null); // 音頻引用
    const beepIntervalRef = useRef(null); // 重複播放計時器引用

    const playBeep = useCallback(() => {
        if (audioRef.current) {
            audioRef.current.currentTime = 0; // 將音頻重設為開頭
            audioRef.current.play().catch((error) => {
                console.log("音樂播放失敗，可能是自動播放限制或其他錯誤:", error);
            });
        }
    }, []);

    // WebSocket 初始化函數
    const initializeWebSocket = useCallback((position_id) => {
        const socket = new WebSocket((process.env.NODE_ENV === "production" ? 'wss://www.ciaowin35.com/ws' : 'ws://debug.ciaowin35.com:9601') + '?id=' + position_id);

        socket.onopen = () => {
            console.log('WebSocket連接成功');
            socket.send(JSON.stringify({ message: 'Hello from client!' }));
        };

        socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            console.log('收到的消息:', message, position_id);

            if (message.type === 'updateOrder' && currentTab === 0) {
                getOrders({ now: 1 }).then((response) => {
                    setTodayOrders(response);
                });
            }

            if (message.type === 'updateProduct') {
                setSystemData((prevSystemData) => ({
                    ...prevSystemData,
                    meal: message.data.meal,
                    menu: message.data.menu,
                    food: message.data.food,
                }));
            }

            if (message.type === 'updateMake') {
                getOrderStatusAPI().then((response)=>{
                    setOrderStatusList(response);
                });

                // setOrderStatusList(message.data);
            }

            if (message.type === 'alert') {  //type=alert status=1/2 msg文字 big是否最大
                if(!beepIntervalRef.current)
                    beepIntervalRef.current = setInterval(playBeep, 1500); // 每秒播放一次

                const option = message.big ? {
                    autoClose: false,
                    closeOnClick: true,
                    style: {
                        background: "rgba(216,0,0,0.8)",
                        color: "white",
                        width: "90vw",
                        height: "90vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "24px",
                        zIndex: 9999,
                    },
                    position: "top-center",
                    onClose: () => clearInterval(beepIntervalRef.current),
                } : {
                    autoClose: false,
                    onClose: () => clearInterval(beepIntervalRef.current),
                } ;

                message.status === 1
                    ? toast.info(message.msg,option)
                    : toast.error(message.msg,option);
            }

            if (message.type === 'notify') {
                playBeep();
                message.status === 1
                    ? toast.info(message.msg)
                    : toast.error(message.msg);
            }
        };

        socket.onerror = (error) => {
            console.error('WebSocket錯誤:', error);
        };

        socket.onclose = () => {
            console.log('WebSocket連接關閉，重試連接');
            setTimeout(() => initializeWebSocket(position_id), 500); // 延遲5秒重試
        };

        setWs(socket);
    }, [playBeep, currentTab]);

    useEffect(() => {
        if (systemData?.position_id && !ws) {
            initializeWebSocket(systemData.position_id);
        }

        return () => {
            if (ws) ws.close();
        };
    }, [initializeWebSocket, systemData]);

    useEffect(() => {
        audioRef.current = new Audio('/beep/beep.mp3');
        audioRef.current.volume = 1;

        const handleUserInteraction = () => {
            audioRef.current.play().catch(() => {
                console.log("靜音播放解鎖音頻權限");
            });
            window.removeEventListener('click', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
        };

        window.addEventListener('click', handleUserInteraction);
        window.addEventListener('touchstart', handleUserInteraction);

        return () => {
            window.removeEventListener('click', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (ws && ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify({ type: 'heartbeat' }));
            }
        }, 30000);

        return () => clearInterval(interval);
    }, [ws]);

    const sendMessage = (message) => {
        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify(message));
        } else {
            console.error('WebSocket連接未開啟，無法發送消息');
        }
    };

    return (
        <GlobalStateContext.Provider value={{
            globalOrder,
            setGlobalOrder,
            systemData,
            setSystemData,
            foodData,
            setFoodData,
            ordersStatusList,
            setOrderStatusList,
            playBeep,
            toast,
            sendMessage,
            currentTab,
            setCurrentTab,
            todayOrders,
            setTodayOrders,
        }}>
            {children}
        </GlobalStateContext.Provider>
    );
};

export const useGlobalOrder = () => useContext(GlobalStateContext);
