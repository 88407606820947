import React, { useState, useEffect, useRef } from "react";
import {
    Grid,
    Button,
    ButtonGroup,
    Typography,
    Box,
    Card,
    CardContent,
    CardActionArea,
} from "@mui/material";
import ImageIcon from "../../images/image_btn.png"; // 圖片圖標
import PersonIcon from "../../images/member_btn.png";
import TableChartIcon from "../../images/table_btn.png";
import NotesIcon from "../../images/note_btn.png";
import { cx } from "../../utils/cx.js";
import Member from "../Dialogs/Member.tsx";
import Payment from "../Dialogs/Payment.js";
import Commodity from "../Dialogs/Commodity.jsx";
import { useForm, Controller, useFieldArray, get } from "react-hook-form";
import { useGlobalOrder } from "../../utils/GlobalStateContext.jsx";
import { orderTypes } from "../../utils/status_table";
import { submitOrderAPI,checkoutAPI } from "../../utils/ordersControlar.js";
import Delete from "../Dialogs/Delete.tsx";

function OrderPage() {
    const { globalOrder, systemData, setGlobalOrder, toast } = useGlobalOrder();
    const [selectedCategory, setSelectedCategory] = useState("");
    const [commodityData, setCommodityData] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);
    //這個用來判斷點擊的是哪個商品彈窗
    const [openCommodity, setOpenCommodity] = useState("");
    const [buyCartItem, setBuyCartItem] = useState({});
    const { register, handleSubmit, setValue, control, getValues, reset, watch } =
        useForm({
            defaultValues: {
                name: "",
                mobile: "",
                address: "",
                note: "",
                get_time: "",
                number: "",
                buycart: [],
                order_total: 0, //總金額
                amount_paid: 0, //已付
                change_amount: 0, //找零
                unpaid_amount: 0, //未付
                payment_text:'',  //付款訊息(通常指退款文字確認unpaid_amount < 0)
                mode: '',
            },
        });
    // 這邊是監聽結帳資料
    const amount_paid = watch("amount_paid");
    const change_amount = watch("change_amount");
    const order_total = watch("order_total");
    const unpaid_amount = watch("unpaid_amount");
    const payment_text = watch("payment_text") ;
    const buycart = watch("buycart");
    const prevGlobalOrderRef = useRef();
    const { fields, append, update, remove } = useFieldArray({
        control,
        name: "buycart",
    });

    const resetAllValues = () => {
        setOrderType(0);
        setIsExpanded(true);
        setGlobalOrder(null);
        reset({
            address: "",
            buycart: [],
            get_time: "",
            method: 1,
            mobile: "",
            name: "",
            note: "",
            number: "",
            order_id: "",
            cash_money: "",
            line_code: "",
            order_total: "",
            unpaid_amount: "",
            change_amount: "",
            amount_paid: "",
            mode: "",
        });
        setSelectedPaymentMethod(0);
        prevGlobalOrderRef.current = {};
    };

    useEffect(() => {
        if (globalOrder) {
            // 比對前後 globalOrder 的差異
            const prevGlobalOrder = prevGlobalOrderRef.current;
            const isSameOrder =
                JSON.stringify(prevGlobalOrder) === JSON.stringify(globalOrder);
            // 如果前後 globalOrder 不同，才執行重置和其他邏輯
            if (!isSameOrder) {
                reset({
                    name: globalOrder?.name || "",
                    mobile: globalOrder?.mobile || "",
                    address: globalOrder?.address || "",
                    note: globalOrder?.note || "",
                    get_time: globalOrder?.get_time || "",
                    order_id: globalOrder?.order_id || "",
                    number: globalOrder?.number || "",
                    buycart: globalOrder?.buycart || [],
                    method: globalOrder?.method || 0,
                    mode: globalOrder?.mode || '',
                    point: globalOrder?.point || 0 ,
                    note1: globalOrder?.note1 || null,
                    note2: globalOrder?.note2 || null,
                });
                orderType !== globalOrder.method &&
                handleOrderTypeChange(globalOrder.method || 0, 2);
            }
            prevGlobalOrderRef.current = globalOrder;
        }
    }, [globalOrder]);

    useEffect(() => {
        // 清理時執行reset()
        return () => {
            resetAllValues();
        };
    }, [reset]);

    const scrollContainerRef = useRef(null); // 使用 useRef 獲取滾動容器的參考
    // 假設 buycart 是來自 props 的一個數組
    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop =
                scrollContainerRef.current.scrollHeight; // 滾動到底部
        }
    }, [buycart]); // 監聽 buycart 的變化

    //ＣallAPI
    const submitOrder = async (print) => {
        const isSameOrder =
            JSON.stringify(prevGlobalOrderRef.current) === JSON.stringify(getValues());

        if (!isSameOrder) {
            try {
                const response = await submitOrderAPI(getValues(), print); // 使用共用的API函式

                if (response.error === 0) {
                    const order = response.order;
                    setGlobalOrder(order);
                    if (print === 1) {
                        toast.success("送出成功");
                        resetAllValues();
                    }
                } else {
                    toast.error(response?.msg ? response.msg : "送出失敗");
                }
            } catch (err) {
                console.error("error:", err);
            }
        }
    };
    const onSubmit = () => {
        submitOrder(1);
    };

    //資料整理
    const filteredMenuItems = () => {
        const meals = systemData.meal.filter(
            (item) => item.category_id === selectedCategory
        );
        return [...meals];
    };
    const renderMenu = () => {
        return systemData.menu
            .filter((menu) => menu.type === null)
            .sort((a, b) => a.sort - b.sort)
            .map((menu) => (
                <Grid
                    item
                    key={`menu_${menu.category_id}`}
                    xs={2.2}
                    className="max-h-[143px]"
                >
                    <Card
                        className="w-full max-h-[143px]"
                        sx={{
                            aspectRatio: "1/1",
                            boxShadow:
                                selectedCategory === menu.category_id
                                    ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                                    : "none",
                            backgroundColor:
                                selectedCategory === menu.category_id ? "#FFF" : "#8E8E8E",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 4,
                        }}
                    >
                        <CardActionArea
                            sx={{
                                height: "100%", // 讓CardActionArea佔滿Card的高度
                                width: "100%", // 讓CardActionArea佔滿Card的寬度
                            }}
                            onClick={() => handleCategoryChange(menu.category_id)}
                        >
                            <CardContent
                                sx={{
                                    padding: 1,
                                    paddingY: "16px !important",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    align="center"
                                    sx={{
                                        fontWeight:
                                            selectedCategory === menu.category_id ? 700 : 400,
                                        fontSize: 28,
                                        letterSpacing: 4,
                                        color:
                                            selectedCategory === menu.category_id
                                                ? "#A43B3B"
                                                : "#E2E2E2",
                                    }}
                                >
                                    {menu.name}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ));
    };
    const renderItems = () => {
        const items = filteredMenuItems();
        return items
            .sort((a, b) => a.sort - b.sort)
            .map((item, index) => (
                <Grid
                    item
                    key={`${item.name}_${item.id}_${index}`}
                    xs={2.2}
                    className="max-h-[143px]"
                >
                    <Card
                        className="w-full max-h-[143px]"
                        sx={{
                            aspectRatio: "1/1",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                            backgroundColor: item.status === 2 ? "#3C3C3C" : "#8E8E8E",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 4,
                        }}
                    >
                        <CardContent
                            sx={{
                                paddingX: 1,
                                paddingY: "16px !important",
                                maxHeight: 143,
                                height: "100%", // 讓CardActionArea佔滿Card的高度
                                width: "100%", // 讓CardActionArea佔滿Card的寬度
                            }}
                            onClick={(event) => {
                                event.stopPropagation();
                                if (item.status === 1 && item.composition.length > 0) {
                                    setOpenCommodity("add");
                                    setCommodityData(item);
                                    handleOpenDialog("commodity");
                                    setBuyCartItem((pre) => {
                                        return {
                                            ...pre,
                                            od_id: item.od_id,
                                            meal_id: item.id,
                                            qty: 1,
                                            note: "",
                                            detail: [],
                                        };
                                    });
                                } else {
                                    handleSingleItem(item);
                                }
                            }}
                        >
              <span
                  className={cx(
                      `text_default_style grid ${
                          item.status >= 2 ? "text-sold_out_gray" : "text-light_gray"
                      }`
                  )}
              >
                <p
                    style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2, // 限制顯示兩行
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                  {item.name} {item.status >= 2 ? (<small>售完</small>) : ''}
                </p>
                  {item?.point ? (`${item.point}點`) : `$${item.price}`}
              </span>
                        </CardContent>
                    </Card>
                </Grid>
            ));
    };
    const handleSingleItem = (item) => {
        const newItem = {
            meal_id: item.id,
            qty: 1,
            note: "",
            od_id: Date.now(),
        };
        const updatedBuyCart =
            buycart?.length > 0 ? buycart.concat(newItem) : [newItem];
        setValue("buycart", updatedBuyCart);
        submitOrder();
    };

    const clickTimeoutRef = useRef(null);

    const handleDeleteItem = (item, index) => {
        // 取消單擊計時器，防止執行單擊操作
        if (clickTimeoutRef.current) {
            clearTimeout(clickTimeoutRef.current);
            clickTimeoutRef.current = null;
        }

        remove(index); // 刪除項目
    };

    //彈窗
    const [dialogOpen, setDialogOpen] = useState({
        member: false,
        commodity: false,
        payment: false,
    });
    // 打開彈窗
    const handleOpenDialog = (dialogType) => {
        setDialogOpen((prev) => ({ ...prev, [dialogType]: true }));
        dialogType === "payment" && clickCheckOut({});
    };

    // 關閉彈窗
    const handleCloseDialog = (dialogType,reason) => {
        console.log("handleCloseDialog",dialogType,reason);
        if (reason === "backdropClick") {
            setDialogOpen((prev) => ({ ...prev, [dialogType]: false }));
            if (dialogType === "payment") {
                if(unpaid_amount === 0){
                    resetAllValues();
                }else{
                    setSelectedPaymentMethod(0);
                }
            }
        } else {
            setDialogOpen((prev) => ({ ...prev, [dialogType]: false }));
            if (dialogType === "payment" && unpaid_amount === 0) {
                resetAllValues();
            }
        }
    };

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };

    // 計算總金額
    const totalAmount = buycart?.reduce((acc, item) => {
        // 檢查 buycart 的 item 是否已有價格，若有且不為 0 則使用，否則從 systemData 查找
        const mainItemPrice = item.money && item.money !== 0
            ? item.money * item.qty
            : (systemData?.meal.find((data) => data.id === item.meal_id)?.price || 0) * item.qty;

        const detailPrice = item.detail
            ? item.detail.reduce((tagAcc, tag) => {
                const foodItem = systemData?.food.find(
                    (food) => food.pd_id === tag.food_id
                );
                const tagPrice = foodItem ? foodItem.price * tag.qty : 0;
                return tagAcc + tagPrice;
            }, 0)
            : 0;

        return acc + mainItemPrice + (item.money ? 0 : (detailPrice * item.qty));
    }, 0);

    //小計金額
    const itemAmount = (item) => {
        const mealData = systemData?.meal.find((data) => data.id === item.meal_id);
        const mainItemPrice = mealData ? mealData.price * item.qty : 0;
        const detailPrice = item.detail
            ? item.detail.reduce((tagAcc, tag) => {
                const foodItem = systemData?.food.find(
                    (food) => food.pd_id === tag.food_id
                );
                const tagPrice = foodItem ? foodItem.price * tag.qty : 0;
                return tagAcc + tagPrice;
            }, 0)
            : 0;
        return mainItemPrice + detailPrice * item.qty;
    };

    //控制案紐收縮
    const [orderType, setOrderType] = useState(0); // 追踪选中的订单类型
    const [isExpanded, setIsExpanded] = useState(true); // 追踪是否展开

    const handleOrderTypeChange = (value, $change = 1) => {
        if (orderType === value) {
            // 如果再次点击同一个选项，重新展开选择
            setIsExpanded(!isExpanded);
        } else {
            // 选择一个选项并收缩
            setOrderType(value);
            setIsExpanded(false);
            setValue("method", value);
            if ($change === 1) submitOrder();
        }
    };
    //checkOut
    const clickCheckOut = async ({ cash_money = 0, line_code = 0 }) => {
        try {
            const response = await checkoutAPI({
                order_id: getValues("order_id"),
                pay_method: getValues("pay_method"),
                cash_money: cash_money,
                line_code: line_code,
            });

            const order = response;
            setValue("order_total", order.order_total);
            setValue("unpaid_amount", order.unpaid_amount);
            setValue("change_amount", order.change_amount);
            setValue("amount_paid", order.amount_paid);
            setValue("payment_text", order.msg);
            if (response.error === 0) {
                if(response.msg)
                    toast.success(response.msg);
            } else {
                toast.error(response.msg || "支付失敗");
                console.error("error", response.msg);
            }
        } catch (err) {
            console.error("error:", err);
        }
    };

    return (
        systemData && (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    container
                    direction="column"
                    sx={{ backgroundColor: "#f0f0f0", height: "100vh" }}
                >
                    {/* 上方ButtonGroup区域，用于选择订单类型 */}
                    <Grid
                        item
                        sx={{
                            marginX: "0px !important",
                            width: "100%",
                            overflow: "auto",
                            height: 80,
                            display: "flex",
                            alignItems: "center",
                            padding: "0px 16px",
                            gap: "16px",
                        }}
                        className="hide-scrollbar"
                    >
                        <Controller
                            name="method"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <ButtonGroup
                                    sx={{
                                        borderRadius: 4,
                                        overflow: "hidden",
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                    }}
                                    aria-label="Order type selection"
                                    {...field}
                                >
                                    {isExpanded
                                        ? orderTypes.map((type) => (
                                            <Button
                                                key={type.value}
                                                sx={{
                                                    fontSize: "16px",
                                                    px: 3,
                                                    border: "1px solid #8E8E8E",
                                                    borderRadius: 4,
                                                    borderColor:
                                                        orderType === type.value ? "#FFF" : "#8E8E8E",
                                                    backgroundColor:
                                                        orderType === type.value ? "#FFF" : "#8E8E8E",
                                                    color:
                                                        orderType === type.value ? "#A43B3B" : "#FFF",
                                                    fontWeight: orderType === type.value ? 700 : 400,
                                                }}
                                                onClick={() => handleOrderTypeChange(type.value)}
                                            >
                                                {type.label}
                                            </Button>
                                        ))
                                        : // 只显示选中的按钮
                                        orderTypes
                                            .filter((type) => type.value === orderType)
                                            .map((type) => (
                                                <Button
                                                    variant="outlined"
                                                    key={type.value}
                                                    sx={{
                                                        height: "36px",
                                                        minWidth: "120px",
                                                        fontSize: "16px",
                                                        px: 3,
                                                        border: "none",
                                                        borderRadius: 4,
                                                        backgroundColor: "#FFF",
                                                        color: "#A43B3B",
                                                        fontWeight: 700,
                                                    }}
                                                    onClick={() => setIsExpanded(true)}
                                                >
                                                    <p className="flex items-center text-[700] text-[20px] leading-6">
                                                        {type.label}
                                                    </p>
                                                </Button>
                                            ))}
                                </ButtonGroup>
                            )}
                        />

                        <Box sx={{ display: "flex", gap: 2 }}>
                            {/* <Button
            variant="outlined"
            sx={{
              backgroundColor: "#FFF",
              borderRadius: 4,
              border: "none",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              gap: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={ImageIcon}
              alt="ImageIcon"
              className="w-[28px] h-[28px]"
            />
            {
              imgArr.length > 0 &&
                imgArr.map((img, index) => {
                  <Box
                    component="img"
                    src={img} // 假设你会用 URL 预览图片
                    alt="Uploaded"
                    key={`order_img_${index}`}
                    sx={{ width: 28, height: 28 }}
                  />;
                })
            }
          </Button> */}
                            {orderType ? (
                                <>
                                    {getValues("get_time_text") ? (<Button
                                            variant="outlined"
                                            sx={{
                                                height: "36px",
                                                minWidth: "120px",
                                                backgroundColor: "#A43B3B",
                                                borderRadius: 4,
                                                border: "none",
                                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                                gap: 1,
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                            onClick={() => {
                                                handleOpenDialog("member");
                                            }}
                                        >
                                            <p className="sidebar_btn_text_b text-[#FFF]">
                                                預約取餐:{getValues("get_time_text")}
                                            </p>
                                        </Button>
                                    ) : ''}

                                    {/* 桌號或單號按钮 */}
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            height: "36px",
                                            minWidth: "120px",
                                            backgroundColor: "#FFF",
                                            borderRadius: 4,
                                            border: "none",
                                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                            gap: 1,
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                        onClick={() => {
                                            handleOpenDialog("member");
                                        }}
                                    >
                                        <img
                                            src={TableChartIcon}
                                            alt="ImageIcon"
                                            className="w-[28px] h-[28px]"
                                        />
                                        <p
                                            className="sidebar_btn_text_b text-sold_out_gray max-w-[320px]"
                                            style={{
                                                whiteSpace: "nowrap", // Prevents text from wrapping
                                                overflow: "hidden", // Ensures content that overflows gets hidden
                                                textOverflow: "ellipsis", // Adds the ellipsis when the text is too long
                                            }}
                                        >
                                            {orderType === "1"
                                                ? getValues("number")
                                                    ? `${getValues("number")}`
                                                    : "桌號"
                                                : getValues("number")
                                                    ? `${getValues("number")}`
                                                    : "單號"}
                                        </p>
                                    </Button>

                                    {/* 備註按钮 */}
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            height: "36px",
                                            minWidth: "120px",
                                            backgroundColor: getValues("note") ? "#A43B3B" : "#FFF",
                                            borderRadius: 4,
                                            border: "none",
                                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                            gap: 1,
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                        onClick={() => {
                                            handleOpenDialog("member");
                                        }}
                                    >
                                        <img
                                            src={NotesIcon}
                                            alt="ImageIcon"
                                            className="w-[28px] h-[28px]"
                                        />
                                        <p
                                            className={cx("sidebar_btn_text_b max-w-[300px]",getValues("note")?"text-[#FFF]":"text-sold_out_gray")}
                                            style={{
                                                whiteSpace: "nowrap", // Prevents text from wrapping
                                                overflow: "hidden", // Ensures content that overflows gets hidden
                                                textOverflow: "ellipsis", // Adds the ellipsis when the text is too long
                                            }}
                                        >
                                            {getValues("note") ? `${getValues("note")}` : "備註"}
                                        </p>
                                    </Button>

                                    <Button
                                        variant="outlined"
                                        sx={{
                                            height: "36px",
                                            minWidth: "120px",
                                            backgroundColor: "#FFF",
                                            borderRadius: 4,
                                            border: "none",
                                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                            gap: 1,
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                        onClick={() => {
                                            handleOpenDialog("member");
                                        }}
                                    >
                                        <img
                                            src={PersonIcon}
                                            alt="ImageIcon"
                                            className="w-[28px] h-[28px]"
                                        />
                                        <p className={cx("sidebar_btn_text_b",getValues("point")?"text-dark_red":"text-sold_out_gray")}>
                                            {getValues("point") ? `${getValues("point")} 點` : "點數"}
                                        </p>
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            height: "36px",
                                            width: "100px",
                                            fontSize: "16px",
                                            px: 3,
                                            border: "none",
                                            borderRadius: 4,
                                            backgroundColor: "#434343",
                                            color: "#FFF",
                                            fontWeight: 700,
                                        }}
                                        onClick={() => {handleOpenDialog('delete')}}
                                    >
                                        <p className="flex items-center text-[700] text-[20px] leading-6">
                                            刪單
                                        </p>
                                    </Button>
                                </>
                            ) : ''}

                        </Box>
                    </Grid>

                    {/* 下方区域 */}
                    <Grid
                        item
                        container
                        direction="row"
                        sx={{
                            height: "calc(100% - 80px)",
                            flexGrow: 1,
                            display: "flex",
                            flexWrap: "nowrap",
                            border: "none",
                            borderTop: "1px solid #797777",
                        }}
                    >
                        {/* 左侧菜单区域 */}
                        <Grid
                            item
                            container
                            direction="row"
                            sx={{
                                paddingY: 2,
                                border: "none",
                                borderRight: "1px solid #797777",
                                height: "100%", // 主容器的高度
                            }}
                            xs={9}
                        >
                            {orderType ? (
                                <>
                                    <Grid
                                        container
                                        spacing={2}
                                        sx={{
                                            gap: 1,
                                            marginX: "auto",
                                            height: "40%", // 占据2/5的高度
                                            overflow: "auto", // 当内容超出时自动滚动
                                        }}
                                        className="hide-scrollbar"
                                    >
                                        {renderMenu()}
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={2}
                                        sx={{
                                            border: "none",
                                            borderTop: "1px solid #FFF",
                                            margin: "16px 0px",
                                            gap: 1,
                                            marginX: "auto",
                                            height: "60%", // 占据3/5的高度
                                            overflow: "auto", // 当内容超出时自动滚动
                                        }}
                                        className="hide-scrollbar"
                                    >
                                        {renderItems()}
                                    </Grid>
                                </>
                            ) : (
                                <h1 className="system_login_title text-dark_red flex items-center justify-center h-full w-full">
                                    請選擇用餐方式
                                </h1>
                            )}
                        </Grid>

                        {/* 右侧订单详情区域 */}
                        <Grid
                            item
                            sx={{
                                padding: 2,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "100%",
                            }}
                            xs={3}
                        >
                            {getValues("mode") ? (
                                <Typography
                                    variant="h6"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        bgcolor: "#8f2222",
                                        height: "30px",
                                        marginX: "-16px",
                                        marginTop: "-16px",
                                        paddingX: "30px",
                                    }}
                                >
                                    <p className="text_default_style text-[#FFF]">{getValues("mode") === 1 ? "提單模式" : (getValues("mode") === 2 ? "改單模式" : "重訂模式")}</p>
                                </Typography>
                            ) : ''}

                            {getValues("note1") ? (
                                <Typography
                                    variant="h6"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        bgcolor: "#053d4e",
                                        height: "30px",
                                        marginX: "-16px",
                                        marginTop: "-16px",
                                        paddingX: "30px",
                                    }}
                                >
                                    <p className="text_default_style text-[#FFF]">{getValues("note1")}</p>
                                </Typography>
                            ) : ''}

                            {getValues("note2") ? (
                                <Typography
                                    variant="h6"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        bgcolor: "#4c4c4c",
                                        height: "30px",
                                        marginX: "-16px",
                                        marginTop: "-16px",
                                        paddingX: "30px",
                                    }}
                                >
                                    <p className="text_default_style text-[#FFF]">{getValues("note2")}</p>
                                </Typography>
                            ) : ''}

                            <Grid
                                container
                                sx={{
                                    marginBottom: 2,
                                    fontWeight: "bold",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingX: "8px",
                                }}
                            >
                                <Grid item xs={6} sx={{ paddingLeft: "8px" }}>
                                    <p className="sidebar_btn_text_b text-sold_out_gray text-left">
                                        品名
                                    </p>
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <p className="sidebar_btn_text_b text-sold_out_gray">數量</p>
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <p className="sidebar_btn_text_b text-sold_out_gray">小計</p>
                                </Grid>
                            </Grid>
                            <div
                                ref={scrollContainerRef}
                                style={{
                                    height: "calc(100% - 200px)",
                                    overflow: "auto",
                                    scrollBehavior: "smooth",
                                }}
                                className="hide-scrollbar"
                            >
                                {
                                    buycart &&
                                    buycart?.map((item, index) => {
                                        const mealData = systemData.meal.find(
                                            (data) => data.id === item.meal_id
                                        );
                                        return (
                                            <Grid
                                                container
                                                key={index}
                                                sx={{
                                                    marginBottom: 1,
                                                    backgroundColor:
                                                        item.print !== 1 ? "#FFF" : "#E2E2E2",
                                                    borderRadius: "8px",
                                                    padding: "8px",
                                                }}
                                                onClick={() => {
                                                    setOpenCommodity("edit");
                                                    setCommodityData(mealData);
                                                    setBuyCartItem(item);
                                                    handleOpenDialog("commodity");
                                                }}
                                                // onDoubleClick={(e) => {handleDeleteItem(item, index) ;}} // 雙擊事件
                                            >
                                                <>
                                                    <Grid item xs={1}>
                                                        <p
                                                            className={cx(
                                                                `sidebar_btn_text_b  text-left`,
                                                                item.print === 1 && "text-dark_red"
                                                            )}
                                                        >
                                                            {`${index + 1}.`}
                                                        </p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <p
                                                            className={cx(
                                                                `sidebar_btn_text_b  text-left`,
                                                                item.print === 1 && "text-dark_red"
                                                            )}
                                                        >
                                                            {mealData?.name}
                                                        </p>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <p
                                                            className={cx(
                                                                `sidebar_btn_text_b `,
                                                                item.print === 1 && "text-dark_red"
                                                            )}
                                                        >
                                                            {item.qty}
                                                        </p>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <p
                                                            className={cx(
                                                                `sidebar_btn_text_b`,
                                                                item.print === 1 && "text-dark_red"
                                                            )}
                                                        >
                                                            ${item?.point ? (item.point+'點') : item?.money}
                                                        </p>
                                                    </Grid>
                                                </>
                                                {item.detail?.length > 0 && (
                                                    <Grid
                                                        sx={{
                                                            marginY: "4px",
                                                            gap: "4px",
                                                        }}
                                                        display={"flex"}
                                                        flexWrap={"wrap"}
                                                    >
                                                        {item.detail.map((tag, index) => {
                                                            // Find the corresponding food item from systemData.food based on categoryId
                                                            const foodData = systemData.food.find(
                                                                (food) => food.pd_id === tag.food_id
                                                            );
                                                            return (
                                                                foodData && (
                                                                    <span
                                                                        key={tag.oi_id}
                                                                        className="sidebar_btn_text_b text-[16px] w-fit flex text-nowrap bg-card_bg_gray rounded-full p-[4px_8px] text-[#FFF]"
                                                                    >
                                      {foodData?.price > 0
                                          ? `+ ${foodData?.name} $ ${foodData?.price}`
                                          : `${foodData?.name}`}
                                    </span>
                                                                )
                                                            );
                                                        })}
                                                    </Grid>
                                                )}

                                                {item.note && (
                                                    <Grid item xs={12}>
                                                        <p className="sidebar_btn_text_b text-ligth_red text-left">{`備註:${item.note}`}</p>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        );
                                    })
                                }
                            </div>

                            <Grid sx={{ marginTop: "auto" }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        bgcolor: "#838383",
                                        height: "64px",
                                        marginX: "-16px",
                                        alignItems: "center",
                                        paddingX: "16px",
                                    }}
                                >
                                    <p className="text_default_style text-[#FFF]">{`消費金額:`}</p>
                                    {fields?.length > 0 && (
                                        <p className="text_default_style text-[#FFF]">
                                            ${totalAmount}
                                        </p>
                                    )}
                                </Typography>

                                <Grid
                                    container
                                    sx={{ marginTop: 2, fontWeight: "bold" }}
                                    spacing={2}
                                >
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            disabled={!getValues("order_id") || buycart.length === 0 || getValues("mode") === 2}
                                            sx={{
                                                backgroundColor: "#FFF",
                                                height: "80px",
                                                borderRadius: 7,
                                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                                padding: "8px 16px",
                                            }}
                                        >
                                            <p className="text_default_style text-[#000] font-[700]">
                                                送單
                                            </p>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            size="large"
                                            disabled={!getValues("order_id") || buycart.length === 0}
                                            sx={{
                                                backgroundColor: "#FFF",
                                                height: "80px",
                                                borderRadius: 7,
                                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                                padding: "8px 16px",
                                            }}
                                            onClick={() => handleOpenDialog("payment")}
                                        >
                                            <p className="text_default_style text-dark_red font-[700]">
                                                結帳
                                            </p>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Member
                        open={dialogOpen.member}
                        handleClose={(event,reason) => handleCloseDialog("member",reason)}
                        register={register}
                        setValue={setValue}
                        control={control}
                        getValues={getValues}
                        submitOrder={submitOrder}
                    />
                    <Payment
                        open={dialogOpen.payment}
                        handleClose={(event,reason) => handleCloseDialog("payment",reason)}
                        getValues={getValues}
                        setValue={setValue}
                        clickCheckOut={clickCheckOut}
                        amount_paid={amount_paid}
                        change_amount={change_amount}
                        order_total={order_total}
                        unpaid_amount={unpaid_amount}
                        selectedPaymentMethod={selectedPaymentMethod}
                        setSelectedPaymentMethod={setSelectedPaymentMethod}
                        payment_text={payment_text}
                    />
                    <Commodity
                        open={dialogOpen.commodity}
                        handleClose={(reason) => handleCloseDialog("commodity",reason)}
                        commodityData={commodityData}
                        systemData={systemData}
                        openCommodity={openCommodity}
                        buyCartItem={buyCartItem}
                        setBuyCartItem={setBuyCartItem}
                        submitOrder={submitOrder}
                        fields={fields}
                        append={append}
                        update={update}
                        remove={remove}
                    />
                </Grid>
                <Delete
                    setOrdersData={()=>{resetAllValues()}}
                    open={dialogOpen.delete}
                    handleClose={() => {handleCloseDialog('delete')} }
                    orderId={getValues("order_id")}
                />
            </form>
        )
    );
}

export default OrderPage;
