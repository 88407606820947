import React, { useState } from "react";
import { Dialog, DialogContent, Button, Grid, Typography, DialogTitle, Radio, RadioGroup, FormControlLabel, TextField } from "@mui/material";
import { removeOrder, getOrders } from "../../utils/ordersControlar.js";

function Delete(props) {
  const { open, handleClose, orderId, setOrdersData } = props;
  const [msg, setMsg] = useState(""); // 儲存選擇的刪除原因
  const [otherReason, setOtherReason] = useState(""); // 儲存使用者自定義的刪除原因

  const handleDelete = async () => {
    try {
      const reason = msg === "其他" ? otherReason : msg; // 若選擇“其他”，則使用自定義原因
      await removeOrder(orderId, reason);
      const newData = await getOrders({});
      setOrdersData(newData);
      handleClose(); // 關閉對話框
    } catch (error) {
      console.error("Error during deletion or fetching orders:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"sm"}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: 4,
          backgroundColor: "#E2E2E2",
          padding: "32px 24px",
        },
      }}
    >
      <DialogTitle 
        sx={{
          fontSize: "24px", 
          fontWeight: 700, 
          marginBottom: "16px", 
          textAlign: "center"
        }}
      >
        刪除原因
      </DialogTitle>
      
      <DialogContent sx={{ padding: 0 }}>
        <RadioGroup
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
          sx={{ padding: "16px" }}
        >
          <FormControlLabel value="無法外送" control={<Radio />} label="無法外送" />
          <FormControlLabel value="餐點售完" control={<Radio />} label="餐點售完" />
          <FormControlLabel value="顧客取消" control={<Radio />} label="顧客取消" />
          <FormControlLabel value="操作錯誤" control={<Radio />} label="操作錯誤" />
          <FormControlLabel value="其他" control={<Radio />} label="其他" />
        </RadioGroup>

        {msg === "其他" && (
          <TextField
            fullWidth
            label="請輸入其他原因"
            variant="outlined"
            value={otherReason}
            onChange={(e) => setOtherReason(e.target.value)}
            sx={{ marginTop: "16px" }}
          />
        )}

        <Grid container justifyContent="space-between" sx={{ marginTop: "24px" }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleClose}
          >
            取消
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDelete}
            disabled={!msg || (msg === "其他" && !otherReason)} // 確保有選擇原因或填寫自定義原因
          >
            確定
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default Delete;
