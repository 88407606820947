// DiningOut.jsx
import React, { useState, useEffect, useRef} from "react";
import {
    Button,
} from "@mui/material";
import {printOrder , getIncomeAPI} from "../../../utils/ordersControlar.js";

function TodayIncome() {

    const [income , setIncome] = useState(null) ;

    const handleIncomedata = async () => {
        try {
            const income = await getIncomeAPI();
            setIncome(income);
        } catch (error) {
            console.error("Failed to change order status:", error);
        }
    };

    useEffect(()=>{
        if(!income){
            handleIncomedata() ;
        }
    },[])

    return (
        income ? (
            <>
                <font size={12}>
                    總營收 ${income.actual_income}<br/>
                    現金營收 ${income.cash_money}<br/>
                    LinePay營收 ${income.line_pay_money}<br/>
                    UberEats營收 ${income.uber_eats_money}<br/>
                </font>
                <div className={"max-h-[20px]"}>
                    <Button
                        key="開啟錢櫃"
                        // disabled={!memberLogin}
                        onClick={() => {
                            printOrder({orderId: 0, type: 5})
                        }}
                        sx={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            border: "1px solid #8E8E8E",
                            borderRadius: 4,
                            borderColor: "#8E8E8E",
                            backgroundColor: "#8E8E8E",
                            color: "#FFF",
                        }}
                    >
                        <p className="flex min-w-[36px]">列印結帳單</p>
                    </Button>
                </div>
            </>
        ) : "載入中..."
    ) ;
}

export default TodayIncome;
